<template>
	<div>
		<a-spin :spinning="false">
		<div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
			<div style="width: 800px;">
			<div class="ft20 ftw500 cl-main">存储设置</div>
			 <a-alert class="mt20" message="1.上线后更换请慎重!存储在哪一方迁移都是很费时间的;  2.将public下面的static中的文件全部上传到存储空间;3.本地存储需要将图片域名绑定到public/upload目录,否则无法上传" type="info" />
			
			
			<div v-if="datas!=null" class="mt20">
				<a-form-model
				   ref="ruleForm"
					:model="datas"
					:label-col="{ span: 4}" 
					:wrapper-col="{ span: 20}">	
					
					<a-form-model-item label="存储设置">
						 <a-radio-group v-model="datas.type">
						      <a-radio value="0">
						        本地存储
						      </a-radio>
						      <a-radio value="1">
						        七牛云
						      </a-radio>
						      <a-radio value="2">
						        阿里云
						      </a-radio>
						      <a-radio value="3">
						        腾讯云
						      </a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="存储域名">
						<a-input v-model="datas.domain" style="width: 300px;" placeholder="格式https://img.woyaou.com" />
					</a-form-model-item>
					<div v-if="datas.type == 1">
						<a-form-model-item label="ak">
							<a-input v-model="datas.qiniu.ak" style="width: 300px;" placeholder="七牛云的秘钥ak" />
						</a-form-model-item>
						<a-form-model-item label="sk">
							<a-input v-model="datas.qiniu.sk" style="width: 300px;" placeholder="七牛云的秘钥sk" />
						</a-form-model-item>
						<a-form-model-item label="bucket">
							<a-input v-model="datas.qiniu.bucket" style="width: 300px;" placeholder="七牛云的存储空间的bucket" />
						</a-form-model-item>
					</div>
					<div v-if="datas.type == 2">
						<a-form-model-item label="AccessKey_id">
							<a-input v-model="datas.ali.access_key_id" style="width: 300px;" placeholder="阿里云的秘钥AccessKey_Id" />
						</a-form-model-item>
						<a-form-model-item label="AccessKey_Secret">
							<a-input v-model="datas.ali.access_key_secret" style="width: 300px;" placeholder="阿里云的秘钥AccessKey_Secret" />
						</a-form-model-item>
						<a-form-model-item label="bucket">
							<a-input v-model="datas.ali.bucket" style="width: 300px;" placeholder="阿里云的存储空间的bucket" />
						</a-form-model-item>
						<a-form-model-item label="Endpoint">
							<a-input v-model="datas.ali.endpoint" style="width: 300px;" placeholder="阿里云的存储空间的bucket对应的外网endpoint节点" />
						</a-form-model-item>
					</div>
					
					<div v-if="datas.type == 3">
						<a-form-model-item label="应用ID">
							<a-input v-model="datas.qcloud.app_id" style="width: 300px;" placeholder="腾讯云的安全秘钥对应的应用ID" />
						</a-form-model-item>
						<a-form-model-item label="SecretId">
							<a-input v-model="datas.qcloud.secret_id" style="width: 300px;" placeholder="腾讯云的安全秘钥SecretId" />
						</a-form-model-item>
						<a-form-model-item label="SecretKey">
							<a-input v-model="datas.qcloud.secret_key" style="width: 300px;" placeholder="腾讯云的安全秘钥SecretKey" />
						</a-form-model-item>
						<a-form-model-item label="存储区域">
							<a-select style="width: 300px;" v-model="datas.qcloud.region">
								<a-select-option value="">请选择</a-select-option>
								<a-select-option v-for="(item,index) in qcloudRegions" :key="index" :value="item.value">{{item.name}}</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="bucket">
							<a-input v-model="datas.qcloud.bucket" style="width: 300px;" placeholder="腾讯云的存储空间的bucket" />
						</a-form-model-item>
					</div>
				
					<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
					      <a-button type="primary"  @click="saveData">
					        保存设置
					      </a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
			</div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				datas:null,
				qcloudRegions:[
					{name:'北京一区（华北）',value:'ap-beijing-1'},
					{name:'北京',value:'ap-beijing'},
					{name:'南京',value:'ap-nanjing'},
					{name:'上海（华东）',value:'ap-shanghai'},
					{name:'广州（华南）',value:'ap-guangzhou'},
					{name:'成都（西南）',value:'ap-chengdu'},
					{name:'重庆',value:'ap-chongqing'},
					{name:'香港',value:'ap-hongkong'}
				],
			}
		},
		created(){
			this.getData();
		},
		methods:{
			getData(){
				this.loading=true;
				this.$http.api('platform/admin/getSettingData',{
					key:'oss'
				}).then(res=>{
					this.loading=false;
					this.datas = res.datas;
				}).catch(res=>{
					this.loading=false;
				})
			},
			saveData(){
				this.loading=true;
				this.$http.api('platform/admin/saveSettingData',{
					key:'oss',
					datas:JSON.stringify(this.datas)
				}).then(res=>{
					this.loading=false;
					this.$message.success('保存成功');
				}).catch(res=>{
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	
</style>